import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from '@/components/Card';
import { Button } from '@/components/Button';
import { CheckboxInput } from '@/components/CheckboxInput';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { SelectInput } from '@/components/SelectInput';
import { SuccessBanner } from '@/components/SuccessBanner';
import { TextInput } from '@/components/TextInput';
import { AccountType } from '@/features/accounts';
import { useAppContext, useAuthContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { useLocationState } from '@/hooks/useLocationState';
import {
  GetQuestionnaireAccessCodeData,
  JobRole,
  Location,
  QueryApiResponseData,
  QuestionnaireAccessCode,
  UpdateQuestionnaireAccessCodeRequestData,
} from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { accountHasProduct, ProductKey } from '@/utils/product';
import { ValidationError } from '@/utils/validation';

import { generateQuestionnaireAccessLink } from '../utils';
import {
  UpdateQuestionnaireAccessCodeFormData,
  UpdateQuestionnaireAccessCodeFormErrors,
} from '../types';

import { LinkCopier } from './LinkCopier';
import { QrCode } from './QrCode';

export function QuestionnaireAccessCodeDetailsPage() {
  const params = useParams();

  const { data } = useApi<GetQuestionnaireAccessCodeData>({
    path: `/questionnaire-access-codes/${params.questionnaireAccessCodePrn}`,
  });

  const locationState = useLocationState<{ accessCode: QuestionnaireAccessCode; }>();

  const [accessCode, setAccessCode] = useState<QuestionnaireAccessCode | null>(
    locationState?.accessCode ?? null,
  );

  useEffect(() => {
    if (data !== undefined) {
      // Bust react-router's cached state and replace it with the fetched data
      setAccessCode(data.questionnaireAccessCode);
      window.history.replaceState({}, '');
    }
  }, [data]);

  const { currentAccount } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    if (accessCode === null) {
      return <PageBodySkeleton />;
    }

    return (
      <div className="flex flex-col gap-4">
        <div className="font-display text-lg pl-4 sm:pl-6 md:pl-0">{accessCode.name}</div>
        <QuestionnaireAccessCodeDetailsPageContent accessCode={accessCode} setAccessCode={setAccessCode} />
      </div>
    );
  } else {
    if (accessCode === null) {
      return (
        <PagePadder>
          <PageBodySkeleton />
        </PagePadder>
      );
    }

    return <QuestionnaireAccessCodeDetailsPageWrapper accessCode={accessCode} setAccessCode={setAccessCode} />;
  }
}

function QuestionnaireAccessCodeDetailsPageWrapper({
  accessCode,
  setAccessCode,
}: {
  accessCode: QuestionnaireAccessCode;
  setAccessCode: Dispatch<SetStateAction<QuestionnaireAccessCode | null>>;
}) {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Access Codes',
          path: '/questionnaire-access-codes'
        },
      ]}
      title={accessCode.name}
    >
      <QuestionnaireAccessCodeDetailsPageContent accessCode={accessCode} setAccessCode={setAccessCode} />
    </Page>
  );
}

function QuestionnaireAccessCodeDetailsPageContent({
  accessCode,
  setAccessCode,
}: {
  accessCode: QuestionnaireAccessCode;
  setAccessCode: Dispatch<SetStateAction<QuestionnaireAccessCode | null>>;
}) {
  const [formData, setFormData] = useState<UpdateQuestionnaireAccessCodeFormData>({
    allowAllLocations: accessCode.allowAllLocations,
    gcdmsEnabled: accessCode.gcdmsEnabled,
    jobRole: null,
    locations: accessCode.allowAllLocations ? [] : accessCode.locations,
    name: accessCode.name,
  });

  const [validationErrors, setValidationErrors] = useState<
    UpdateQuestionnaireAccessCodeFormErrors
  >({});

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const [rotateSubmitState, setRotateSubmitState] = useState<
    'READY' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('READY');

  const [toggleSubmitState, setToggleSubmitState] = useState<
    'READY' | 'SUBMITTING' | 'ERROR'
  >('READY');

  const { currentAccount, currentEmployer } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const employer = currentEmployer ?? null;

  const {
    data: jobRolesQuery,
    failed: jobRolesFailed,
    isLoading: jobRolesIsLoading,
  } = useApi<QueryApiResponseData<JobRole>>({
    disable: employer === null,
    path: `/job-roles?employerPrn=${employer?.prn}&sortBy=name`
  });

  const {
    data: locationsQuery,
    failed: locationsFailed,
    isLoading: locationsIsLoading,
  } = useApi<QueryApiResponseData<Location>>({
    disable: employer === null,
    path: `/locations?employerPrn=${employer?.prn}&sortBy=name`
  });

  // Attach the job role stub in the form data to the full job role from the API
  useEffect(() => {
    if (jobRolesQuery !== undefined) {
      const jobRole = jobRolesQuery.data.find((jobRole) => jobRole.prn === accessCode.jobRole.prn);

      if (jobRole === undefined) {
        throw new Error();
      }

      setFormData((oldFormData) => ({
        ...oldFormData,
        jobRole,
      }));
    }
  }, [jobRolesQuery, accessCode.jobRole.prn]);

  function validate(): UpdateQuestionnaireAccessCodeRequestData {
    let requestData: Partial<UpdateQuestionnaireAccessCodeRequestData> = {
      employer: accessCode.employer,
      gcdmsEnabled: formData.gcdmsEnabled,
      isEnabled: accessCode.isEnabled,
      prn: accessCode.prn,
    };

    const newErrors: UpdateQuestionnaireAccessCodeFormErrors = {};

    if (formData.jobRole !== null) {
      requestData.jobRole = {
        prn: formData.jobRole.prn,
      };
    } else {
      newErrors.jobRole = 'This field is required.';
    }

    if (formData.allowAllLocations) {
      requestData.allowAllLocations = true;
    } else {
      if (formData.locations.length > 0) {
        requestData = {
          ...requestData,
          allowAllLocations: false,
          locations: formData.locations.map((formLocation) => ({
            prn: formLocation.prn,
          })),
        };
      } else {
        newErrors.locations = 'You must select at least one location.';
      }
    }

    if (formData.name.trim().length > 0) {
      requestData.name = formData.name;
    } else {
      newErrors.name = 'This field is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as UpdateQuestionnaireAccessCodeRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: UpdateQuestionnaireAccessCodeRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as UpdateQuestionnaireAccessCodeFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/questionnaire-access-codes/${accessCode.prn}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'PUT',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 200) {
        throw new Error();
      }

      const data = await result.json() as GetQuestionnaireAccessCodeData;

      setAccessCode(data.questionnaireAccessCode);
      setSubmitState('SUCCESS');
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return (employer === null || jobRolesIsLoading || jobRolesFailed || locationsIsLoading || locationsFailed) ? (
    <PageBodySkeleton />
  ) : (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap gap-4 px-4 sm:px-6 md:px-0 self-end">
        {accessCode.isEnabled && (
          <>
            <LinkCopier as="button" accessCode={accessCode} />
            {currentAccount?.hasAgreement && (
              <Button
                disabled={rotateSubmitState === 'SUBMITTING'}
                onClick={() => {
                  async function rotate() {
                    try {
                      if (confirm('Rotating this access code will make it immediately unusable for anyone already possessing the QR code or link. Are you sure you wish to continue?')) {
                        setRotateSubmitState('SUBMITTING');

                        const accessToken = await getAccessToken();

                        const response = await fetch(
                          `${import.meta.env.VITE_API_BASE_URL}/questionnaire-access-codes/${accessCode.prn}/rotate`,
                          {
                            headers: {
                              authorization: `Bearer ${accessToken}`,
                            },
                            method: 'POST',
                            body: JSON.stringify({
                              version: accessCode.version,
                            }),
                          },
                        );

                        if (response.status !== 200) {
                          throw new Error();
                        }

                        const resultJson = await response.json() as GetQuestionnaireAccessCodeData;

                        setAccessCode(resultJson.questionnaireAccessCode);
                        setRotateSubmitState('SUCCESS');

                        setTimeout(() => {
                          setRotateSubmitState('READY');
                        }, 2000);
                      }
                    } catch (err) {
                      setRotateSubmitState('ERROR');
                    }
                  }

                  void rotate();
                }}
              >
                <MaterialSymbol
                  className={rotateSubmitState === 'SUBMITTING' ? 'animate-spin' : ''}
                  icon={rotateSubmitState === 'SUCCESS' ? 'check' : 'cached'}
                />
                {rotateSubmitState === 'SUCCESS' ? 'Rotated' : 'Rotate'}
              </Button>
            )}
          </>
        )}
        {currentAccount?.hasAgreement && (
          <Button
            disabled={toggleSubmitState === 'SUBMITTING'}
            onClick={() => {
              async function toggleEnabled() {
                try {
                  setToggleSubmitState('SUBMITTING');

                  const accessToken = await getAccessToken();

                  const { locations, ...otherAccessCodeFields } = accessCode;

                  const response = await fetch(
                    `${import.meta.env.VITE_API_BASE_URL}/questionnaire-access-codes/${accessCode.prn}`,
                    {
                      headers: {
                        authorization: `Bearer ${accessToken}`,
                      },
                      method: 'PUT',
                      body: JSON.stringify({
                        ...otherAccessCodeFields,
                        ...(!accessCode.allowAllLocations && { locations }),
                        isEnabled: !accessCode.isEnabled,
                      }),
                    },
                  );

                  if (response.status !== 200) {
                    throw new Error();
                  }

                  const resultJson = await response.json() as GetQuestionnaireAccessCodeData;

                  setAccessCode(resultJson.questionnaireAccessCode);

                  setToggleSubmitState('READY');
                } catch (err) {
                  setToggleSubmitState('ERROR');
                }
              }

              void toggleEnabled();
            }}
          >
            <MaterialSymbol
              className={toggleSubmitState === 'SUBMITTING' ? 'animate-spin' : ''}
              icon={toggleSubmitState === 'SUBMITTING' ? 'cached' : accessCode.isEnabled ? 'block' : 'bolt'}
            />
            {accessCode.isEnabled ? 'Disable' : 'Enable'}
          </Button>
        )}
      </div>
      {rotateSubmitState === 'ERROR' && (
        <ErrorBanner message="Could not rotate access code. Please check your internet connection and try again." />
      )}
      {toggleSubmitState === 'ERROR' && (
        <ErrorBanner message={`Could not ${accessCode.isEnabled ? 'disable' : 'enable'} access code. Please check your internet connection and try again.`} />
      )}
      <Card title="Access Code Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.name} label="Access Code Name">
              {() => currentAccount?.hasAgreement ? (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      name: evt.target.value,
                    });
                  }}
                  value={formData.name}
                />
              ) : (
                <div>{formData.name}</div>
              )}
            </FormGroup>
            <FormGroup label="Enabled">
              {() => (
                <div>{accessCode.isEnabled ? 'Yes' : 'No'}</div>
              )}
            </FormGroup>
            <FormGroup label="Job Role">
              {({ id }) => currentAccount?.hasAgreement ? (
                <SelectInput<JobRole>
                  id={id}
                  onChange={(jobRole) => {
                    setFormData({
                      ...formData,
                      jobRole,
                    });
                  }}
                  options={jobRolesQuery.data.map((jobRole) => ({
                    key: jobRole.prn,
                    label: jobRole.name,
                    value: jobRole,
                  }))}
                  value={formData.jobRole}
                />
              ) : (
                <div>{formData.jobRole?.name}</div>
              )}
            </FormGroup>
            <FormGroup
              error={validationErrors.locations}
              label="Location(s)"
              sublabel={
                currentAccount?.hasAgreement
                  ? 'Select which work location(s) the employee should be allowed to choose from when completing their questionnaire.'
                  : undefined
              }
            >
              {() => currentAccount?.hasAgreement ? (
                <>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <input
                        checked={formData.allowAllLocations}
                        className="h-4 w-4 border-gray-300 text-purple-600 focus:ring-purple-600 cursor-pointer"
                        id="allowAllLocations-true"
                        onChange={(evt) => {
                          setFormData({
                            ...formData,
                            allowAllLocations: evt.target.checked,
                          })
                        }}
                        type="radio"
                      />
                      <label
                        className="ml-3 block leading-6 cursor-pointer"
                        htmlFor="allowAllLocations-true"
                      >
                        All Locations
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        checked={!formData.allowAllLocations}
                        className="h-4 w-4 border-gray-300 text-purple-600 focus:ring-purple-600 cursor-pointer"
                        id="allowAllLocations-false"
                        onChange={(evt) => {
                          setFormData({
                            ...formData,
                            allowAllLocations: !evt.target.checked,
                          })
                        }}
                        type="radio"
                      />
                      <label
                        className="ml-3 block leading-6 cursor-pointer"
                        htmlFor="allowAllLocations-false"
                      >
                        Specific Locations:
                      </label>
                    </div>
                  </div>
                  <div className="ml-6 space-y-2">
                    {locationsQuery.data.map((location) => (
                      <div className="flex items-center" key={location.prn}>
                        <input
                          checked={(
                            formData.allowAllLocations ||
                            formData.locations.find(({ prn }) => prn === location.prn) !== undefined
                          )}
                          className={`
                            h-4 w-4 rounded border-gray-300 text-purple-500 focus:ring-purple-600
                            ${formData.allowAllLocations
                              ? 'cursor-not-allowed opacity-25'
                              : 'cursor-pointer'
                            }
                          `}
                          disabled={formData.allowAllLocations}
                          id={`locations-${location.prn}`}
                          onChange={(evt) => {
                            if (evt.target.checked) {
                              setFormData({
                                ...formData,
                                locations: [
                                  ...formData.locations,
                                  location,
                                ],
                              });
                            } else {
                              setFormData({
                                ...formData,
                                locations: formData.locations.filter(
                                  (formLocation) => formLocation.prn !== location.prn,
                                ),
                              });
                            }
                          }}
                          type="checkbox"
                        />
                        <label
                          className={`
                            ml-3 block leading-6
                            ${formData.allowAllLocations
                              ? 'cursor-not-allowed opacity-50'
                              : 'cursor-pointer'
                            }
                          `}
                          htmlFor={`locations-${location.prn}`}
                        >
                          {location.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="flex flex-col gap-y-2">
                  {formData.allowAllLocations
                    ? <div className="italic">all locations</div>
                    : formData.locations.map((location) => (
                      <div key={location.prn}>{location.name}</div>
                    ))
                  }
                </div>
              )}
            </FormGroup>
            {accountHasProduct(employer, ProductKey.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE) && (
              <FormGroup label="Ground Cannabis Dust">
                {(({ id }) => currentAccount?.hasAgreement ? (
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <CheckboxInput
                        checked={formData.gcdmsEnabled}
                        id={id}
                        onChange={(gcdmsEnabled) => {
                          setFormData({
                            ...formData,
                            gcdmsEnabled,
                          });
                        }}
                      />
                    </div>
                    <div className="ml-3 leading-6">
                      <label
                        className="cursor-pointer"
                        htmlFor={id}
                      >
                        Include medical surveillance for ground cannabis dust with this questionnaire
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-start justify-start gap-x-2">
                    <MaterialSymbol
                      icon={accessCode.gcdmsEnabled ? 'check_box' : 'check_box_outline_blank'}
                    />
                    Include medical surveillance for ground cannabis dust with this questionnaire
                  </div>
                ))}
              </FormGroup>
            )}
            {accessCode.isEnabled && (
              <FormGroup label="QR Code">
                {() => (
                  <div className="bg-white rounded-md p-4">
                    <QrCode
                      employerName={accessCode.employer.preferredName}
                      name={accessCode.name}
                      url={generateQuestionnaireAccessLink({ accessCode })}
                    />
                  </div>
                )}
              </FormGroup>
            )}
          </div>
        </div>
      </Card>
      {submitState === 'SUCCESS' && (
        <SuccessBanner message="Changes to access code successfully saved." />
      )}
      {submitState === 'ERROR' && (
        <ErrorBanner message="Could not save access code. Please check your internet connection and try again." />
      )}
      {currentAccount?.hasAgreement && (
        <div className="flex justify-center">
          <Button
            disabled={submitState === 'SUBMITTING'}
            loading={submitState === 'SUBMITTING'}
            onClick={() => {
              void submit();
            }}
            size="lg"
          >
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
}
