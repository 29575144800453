import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { CannabisJobTask, QueryApiResponseData } from '@/types/api';

export function CannabisJobTasksList() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const { data } = useApi<QueryApiResponseData<CannabisJobTask>>({
    path: `/cannabis-job-tasks?employerPrn=${currentEmployerPrn}&sortBy=name`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <Table
      actionItem={
        <Button
          onClick={() => {
            navigate(
              currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                ? `/employers/${currentEmployerPrn}/settings/cannabis-job-tasks/new`
                : '/settings/cannabis-job-tasks/new',
            );
          }}
          size="action-item"
        >
          <MaterialSymbol block icon="add" />
          <span className="hidden sm:inline">New Cannabis Job Task</span>
        </Button>
      }
      title="Cannabis Job Tasks"
    >
      <TableBody>
        {data.data.length === 0 ? (
          <TableRow className="italic">
            <TableCell firstCol lastCol>No cannabis job tasks have been set up. Click the + button above to add a job task.</TableCell>
          </TableRow>
        ) : data.data.map((cannabisJobTask) => (
          <TableRow key={cannabisJobTask.prn}>
            <TableCell firstCol header lastCol>{cannabisJobTask.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
