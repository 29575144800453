import { ReactNode } from 'react';

export function Table({
  actionItem,
  children,
  title,
}: {
  actionItem?: ReactNode;
  children: ReactNode;
  title?: string;
}) {
  return (
    <div className="bg-gray-920 md:rounded-md overflow-hidden">
      {(title !== undefined || actionItem !== undefined) && (
        <div className="flex gap-x-4 bg-gray-900 px-4 sm:px-6 py-3.5">
          {title !== undefined && (
            <div className="grow font-semibold truncate">{title}</div>
          )}
          {actionItem}
        </div>
      )}
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full">
            {children}
          </table>
        </div>
      </div>
    </div>
  );
}
