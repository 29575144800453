import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { MedicalSurveillanceEvaluationStatus } from '@/features/medical-surveillance';
import { RecordSubtype } from '@/features/reporting';
import { RespiratorMedicalEvaluationStatus } from '@/features/respirator-medical-evaluations';
import { useApi } from '@/hooks/useApi';
import { ProximaRecord, QueryApiResponseData } from '@/types/api';
import { NAME_SUFFIX_LABELS } from '@/utils/pii';
import { accountHasProduct, ProductKey } from '@/utils/product';

const mseStatusLabels = {
  [MedicalSurveillanceEvaluationStatus.CLEARED]: 'Cleared',
  [MedicalSurveillanceEvaluationStatus.DEFERRED]: 'Deferred',
  [MedicalSurveillanceEvaluationStatus.EXPIRED_INTERVIEW]: 'Expired',
  [MedicalSurveillanceEvaluationStatus.PENDING_INTERVIEW]: 'Interview Needed',
  [MedicalSurveillanceEvaluationStatus.PENDING_REVIEW]: 'In Progress',
};

const rmeStatusLabels = {
  [RespiratorMedicalEvaluationStatus.APPROVED]: 'Approved',
  [RespiratorMedicalEvaluationStatus.DEFERRED]: 'Deferred',
  [RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW]: 'Expired',
  [RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED]: 'Partially Approved',
  [RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW]: 'Interview Needed',
  [RespiratorMedicalEvaluationStatus.PENDING_REVIEW]: 'In Progress',
};

export function DashboardPage() {
  const { currentAccount } = useAppContext();

  const hasGcdms = (
    currentAccount !== undefined
    && accountHasProduct(currentAccount, ProductKey.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE)
  );

  const {
    data: rmesData,
    failed: rmesFailed,
    isLoading: rmesIsLoading,
  } = useApi<QueryApiResponseData<ProximaRecord>>({
    disable: currentAccount === undefined,
    path: `/records?${
      currentAccount?.accountType === AccountType.SERVICE_PROVIDER
        ? 'serviceProviderPrn'
        : 'employerPrn'
    }=${
      currentAccount?.prn
    }&respiratorMedicalEvaluationStatus=PENDING_REVIEW,PENDING_INTERVIEW`,
  });

  const {
    data: gcdmsesData,
    failed: gcdmsesFailed,
    isLoading: gcdmsesIsLoading,
  } = useApi<QueryApiResponseData<ProximaRecord>>({
    disable: !hasGcdms,
    path: `/records?${
      currentAccount?.accountType === AccountType.SERVICE_PROVIDER
        ? 'serviceProviderPrn'
        : 'accountPrn'
    }=${
      currentAccount?.prn
    }&groundCannabisDustMedicalSurveillanceEvaluationStatus=PENDING_REVIEW,PENDING_INTERVIEW`,
  });

  const hour = (new Date()).getHours();

  let message: string;

  if (hour < 3 || hour >= 18) {
    message = 'Good evening';
  } else if (hour >= 3 && hour < 12) {
    message = 'Good morning';
  } else {
    message = 'Good afternoon';
  }

  return (
    <Page title={`${message}! Welcome to Proxima.`}>
      <div className="flex flex-col gap-y-4">
        {rmesFailed ? null : rmesIsLoading ? (
          <PageBodySkeleton />
        ) : rmesData.data.length > 0 && (
          <Table title="Respirator Evaluations In Progress">
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell firstCol>Name</TableHeaderCell>
                <TableHeaderCell>Job Role</TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {rmesData.data.map((record) => (
                <TableRow key={record.prn}>
                  <TableCell firstCol header>
                    {record.employee.lastName}
                    ,{' '}
                    {record.employee.firstName}
                    {record.employee.nameSuffix !== null && (
                      ` ${NAME_SUFFIX_LABELS[record.employee.nameSuffix]}`
                    )}
                  </TableCell>
                  <TableCell>
                    {record.jobRole.name}
                  </TableCell>
                  <TableCell>
                    {record.location.name}
                  </TableCell>
                  <TableCell>
                    {record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION &&
                      rmeStatusLabels[record.status]
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {hasGcdms && (gcdmsesFailed ? null : gcdmsesIsLoading ? (
          <PageBodySkeleton />
        ) : gcdmsesData.data.length > 0 && (
          <Table title="GCD Screenings In Progress">
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell firstCol>Name</TableHeaderCell>
                <TableHeaderCell>Job Role</TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {gcdmsesData.data.map((record) => (
                <TableRow key={record.prn}>
                  <TableCell firstCol header>
                    {record.employee.lastName}
                    ,{' '}
                    {record.employee.firstName}
                    {record.employee.nameSuffix !== null && (
                      ` ${NAME_SUFFIX_LABELS[record.employee.nameSuffix]}`
                    )}
                  </TableCell>
                  <TableCell>
                    {record.jobRole.name}
                  </TableCell>
                  <TableCell>
                    {record.location.name}
                  </TableCell>
                  <TableCell>
                    {record.subtype === RecordSubtype.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION &&
                      mseStatusLabels[record.status]
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </div>
    </Page>
  );
}
