import { ReactNode } from 'react';

export function TableHeaderCell({
  children,
  firstCol = false,
  lastCol = false,
}: {
  children: ReactNode;
  firstCol?: boolean;
  lastCol?: boolean;
}) {
  return (
    <th
      className={`
        ${firstCol ? 'pl-4 sm:pl-6' : 'pl-3'}
        ${lastCol ? 'pr-4 sm:pl-6' : 'pr-3'}
        py-3.5 text-left text-sm font-semibold border-b border-gray-800 whitespace-nowrap
      `}
      scope="col"
    >
      {children}
    </th>
  );
}
