import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { QueryApiResponseData, QuestionnaireAccessCode } from '@/types/api';

import { LinkCopier } from './LinkCopier';
import { QrCodePopup } from './QrCodePopup';

export function QuestionnaireAccessCodesListPage() {
  const { currentAccount } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return <QuestionnaireAccessCodesListPageContent />;
  } else {
    return <QuestionnaireAccessCodesListPageWrapper />;
  }
}

function QuestionnaireAccessCodesListPageWrapper() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Access Codes"
    >
      <QuestionnaireAccessCodesListPageContent />
    </Page>
  );
}

function QuestionnaireAccessCodesListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const [currentDisplayedQrCode, setCurrentDisplayedQrCode] = useState<
    QuestionnaireAccessCode | null
  >(null);

  const { data } = useApi<QueryApiResponseData<QuestionnaireAccessCode>>({
    disable: currentEmployerPrn === undefined || currentEmployerPrn === null,
    path: `/questionnaire-access-codes?employerPrn=${currentEmployerPrn}&sortBy=name`,
  });

  const navigate = useNavigate();

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <>
      <QrCodePopup
        currentDisplayedQrCode={currentDisplayedQrCode}
        onClose={() => {
          setCurrentDisplayedQrCode(null);
        }}
      />
      <Table
        actionItem={
          currentAccount?.hasAgreement
            ? (
              <Button
                onClick={() => {
                  navigate(
                    currentAccount.accountType === AccountType.SERVICE_PROVIDER
                      ? `/employers/${currentEmployerPrn}/questionnaire-access-codes/new`
                      : '/questionnaire-access-codes/new',
                  );
                }}
                size="action-item"
              >
                <MaterialSymbol icon="add" />
                <span className="hidden sm:inline">New Access Code</span>
              </Button>
            )
            : undefined
        }
        title="Access Codes"
      >
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell firstCol>Name</TableHeaderCell>
            <TableHeaderCell>Job Role</TableHeaderCell>
            <TableHeaderCell>Location(s)</TableHeaderCell>
            <TableHeaderCell>Copy Link</TableHeaderCell>
            <TableHeaderCell>QR Code</TableHeaderCell>
            <TableHeaderCell lastCol>Enabled</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {data.data.map((accessCode) => (
            <TableRow
              className="align-top cursor-pointer"
              key={accessCode.prn}
              onClick={() => {
                navigate(
                  currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                    ? `/employers/${currentEmployerPrn}/questionnaire-access-codes/${accessCode.prn}`
                    : `/questionnaire-access-codes/${accessCode.prn}`,
                  {
                    state: { accessCode },
                  },
                );
              }}
            >
              <TableCell firstCol header>
                <Link
                  state={{ accessCode }}
                  to={
                    currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                      ? `/employers/${currentEmployerPrn}/questionnaire-access-codes/${accessCode.prn}`
                      : `/questionnaire-access-codes/${accessCode.prn}`
                  }
                >
                  {accessCode.name}
                </Link>
              </TableCell>
              <TableCell>{accessCode.jobRole.name}</TableCell>
              <TableCell>
                <LocationsSummary accessCode={accessCode} />
              </TableCell>
              <TableCell>
                <LinkCopier as="link" accessCode={accessCode} />
              </TableCell>
              <TableCell>
                <Link onClick={() => {
                  setCurrentDisplayedQrCode(accessCode);
                }}>
                  <MaterialSymbol icon="qr_code" />
                </Link>
              </TableCell>
              <TableCell lastCol>
                {accessCode.isEnabled && (
                  <MaterialSymbol icon="check" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

function LocationsSummary({
  accessCode,
}: {
  accessCode: QuestionnaireAccessCode;
}) {
  if (accessCode.allowAllLocations) {
    return <div className="italic">all locations</div>
  } else if (accessCode.locations.length === 1) {
    return accessCode.locations[0].name;
  } else {
    return <div className="italic">{accessCode.locations.length} locations</div>
  }
}
