import { RadioGroup } from '@headlessui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from '@/components/Card';
import { Button } from '@/components/Button';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormError } from '@/components/FormError';
import { FormGroup } from '@/components/FormGroup';
import { FormLabel } from '@/components/FormLabel';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page, PagePadder } from '@/components/Page';
import { PageDivider } from '@/components/PageDivider';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { RadioGroupOption } from '@/components/RadioGroupOption';
import { SelectGroup } from '@/components/SelectGroup';
import { SelectInput } from '@/components/SelectInput';
import { SuccessBanner } from '@/components/SuccessBanner';
import { TextInput } from '@/components/TextInput';
import { TextareaInput } from '@/components/TextareaInput';
import { AccountType } from '@/features/accounts';
import { useAppContext, useAuthContext } from '@/features/app';
import {
  PhysicalExertionLevel,
  PpeType,
  RESPIRATOR_TYPE_OPTIONS,
  RESPIRATOR_TYPE_SHORT_LABELS,
  RespiratorType,
  RespiratorUsageDuration,
  RespiratorUsageScenario,
  RespiratoryHazard,
  WorkEnvironment,
} from '@/features/respiratory-protection';
import { useApi } from '@/hooks/useApi';
import { useLocationState } from '@/hooks/useLocationState';
import { GetJobRoleData, JobRole, UpdateJobRoleRequestData } from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { YesNo } from '@/utils/form';
import { ValidationError } from '@/utils/validation';

import { UpdateJobRoleFormData, UpdateJobRoleFormErrors } from '../types';

type TypeUsageRequestData = UpdateJobRoleRequestData['modules']['respiratorMedicalEvaluation']['respiratorTypeUsages'][number];

export function JobRoleDetailsPage() {
  const params = useParams();

  const { data } = useApi<GetJobRoleData>({
    path: `/job-roles/${params.jobRolePrn}`,
  });

  const locationState = useLocationState<{ jobRole: JobRole; }>();

  const [jobRole, setJobRole] = useState<JobRole | null>(locationState?.jobRole ?? null);

  useEffect(() => {
    if (data !== undefined) {
      // Bust react-router's cached state and replace it with the fetched data
      setJobRole(data.jobRole);
      window.history.replaceState({}, '');
    }
  }, [data]);

  const { currentAccount } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    if (jobRole === null) {
      return <PageBodySkeleton />;
    }

    return (
      <div className="flex flex-col gap-4">
        <div className="font-display text-lg pl-4 sm:pl-6 md:pl-0">{jobRole.name}</div>
        <JobRoleDetailsPageContent jobRole={jobRole} setJobRole={setJobRole} />
      </div>
    );
  } else {
    if (jobRole === null) {
      return (
        <PagePadder>
          <PageBodySkeleton />
        </PagePadder>
      );
    }

    return <JobRoleDetailsPageWrapper jobRole={jobRole} setJobRole={setJobRole} />
  }
}

function JobRoleDetailsPageWrapper({
  jobRole,
  setJobRole,
}: {
  jobRole: JobRole;
  setJobRole: Dispatch<SetStateAction<JobRole | null>>;
}) {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Job Roles',
          path: '/job-roles'
        },
      ]}
      title={jobRole.name}
    >
      <JobRoleDetailsPageContent jobRole={jobRole} setJobRole={setJobRole} />
    </Page>
  );
}

function JobRoleDetailsPageContent({
  jobRole,
  setJobRole,
}: {
  jobRole: JobRole;
  setJobRole: Dispatch<SetStateAction<JobRole | null>>;
}) {
  const [newRespiratorType, setNewRespiratorType] = useState<RespiratorType | null>(null);

  const [formData, setFormData] = useState<UpdateJobRoleFormData>({
    description: jobRole.description,
    name: jobRole.name,
    respiratorTypeUsages: jobRole.modules.respiratorMedicalEvaluation.respiratorTypeUsages.map(
      (typeUsage) => ({
        additionalPpeTypes: typeUsage.additionalPpeTypes,
        averageUsageDuration: typeUsage.averageUsageDuration,
        idlhEnvironment: typeUsage.idlhEnvironment ? YesNo.YES : YesNo.NO,
        physicalExertionLevel: typeUsage.physicalExertionLevel,
        respiratorType: typeUsage.respiratorType,
        respiratoryHazards: typeUsage.respiratoryHazards,
        usageScenarios: typeUsage.usageScenarios,
        workEnvironments: typeUsage.workEnvironments,
      }),
    ),
  });

  const [validationErrors, setValidationErrors] = useState<UpdateJobRoleFormErrors>({
    respiratorTypeUsages: {},
  });

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const { currentEmployerPrn } = useAppContext();
  const { getAccessToken } = useAuthContext();

  useEffect(() => {
    const newRespiratorTypeUsages = [
      ...formData.respiratorTypeUsages,
    ];

    let shouldUpdate = false;

    for (const typeUsageData of newRespiratorTypeUsages) {
      if (typeUsageData.respiratoryHazards.some(
        (respiratoryHazard) => (
          respiratoryHazard === RespiratoryHazard.OXYGEN_DEFICIENT
          || respiratoryHazard === RespiratoryHazard.OXYGEN_ENRICHED
        ),
      )) {
        if (typeUsageData.idlhEnvironment !== YesNo.YES) {
          typeUsageData.idlhEnvironment = YesNo.YES;
          shouldUpdate = true;
        }
      }
    }

    if (shouldUpdate) {
      setFormData({
        ...formData,
        respiratorTypeUsages: newRespiratorTypeUsages,
      });
    }
  }, [formData]);

  function validate(): UpdateJobRoleRequestData {
    if (currentEmployerPrn === undefined || currentEmployerPrn === null) {
      throw new Error();
    }

    const requestData: Partial<UpdateJobRoleRequestData> = {
      modules: {
        respiratorMedicalEvaluation: {
          respiratorTypeUsages: [],
        },
      },
      prn: jobRole.prn,
    };

    const newErrors: UpdateJobRoleFormErrors = {
      respiratorTypeUsages: {},
    };

    if (formData.name.trim().length > 0) {
      requestData.name = formData.name;
    } else {
      newErrors.name = 'This field is required.';
    }

    if (formData.description.trim().length > 0) {
      requestData.description = formData.description;
    } else {
      newErrors.description = 'This field is required.';
    }

    if (newRespiratorType !== null) {
      newErrors.allRespiratorTypeUsages = 'Please add or unselect this respirator type.';
    } else if (formData.respiratorTypeUsages.length === 0) {
      newErrors.allRespiratorTypeUsages = 'You must add at least one respirator type.';
    }

    for (const typeUsageFormData of formData.respiratorTypeUsages) {
      const typeUsageRequestData: Partial<TypeUsageRequestData> = {
        additionalPpeTypes: typeUsageFormData.additionalPpeTypes,
        respiratorType: typeUsageFormData.respiratorType,
        workEnvironments: typeUsageFormData.workEnvironments,
      };

      const newTypeUsageErrors: UpdateJobRoleFormErrors['respiratorTypeUsages'][RespiratorType] = {};

      if (typeUsageFormData.respiratoryHazards.length > 0) {
        typeUsageRequestData.respiratoryHazards = typeUsageFormData.respiratoryHazards;
      } else {
        newTypeUsageErrors.respiratoryHazards = 'You must select at least one respiratory hazard.';
      }

      if (typeUsageFormData.idlhEnvironment !== null) {
        typeUsageRequestData.idlhEnvironment = typeUsageFormData.idlhEnvironment === YesNo.YES;
      } else {
        newTypeUsageErrors.idlhEnvironment = 'This field is required.';
      }

      if (typeUsageFormData.usageScenarios.length > 0) {
        typeUsageRequestData.usageScenarios = typeUsageFormData.usageScenarios;
      } else {
        newTypeUsageErrors.usageScenarios = 'You must select at least one usage scenario.';
      }

      if (typeUsageFormData.averageUsageDuration !== null) {
        typeUsageRequestData.averageUsageDuration = typeUsageFormData.averageUsageDuration;
      } else {
        newTypeUsageErrors.averageUsageDuration = 'This field is required.';
      }

      if (typeUsageFormData.physicalExertionLevel !== null) {
        typeUsageRequestData.physicalExertionLevel = typeUsageFormData.physicalExertionLevel;
      } else {
        newTypeUsageErrors.physicalExertionLevel = 'This field is required.';
      }

      if (Object.keys(newTypeUsageErrors).length === 0) {
        requestData.modules?.respiratorMedicalEvaluation.respiratorTypeUsages.push(
          typeUsageRequestData as TypeUsageRequestData,
        );
      } else {
        newErrors.respiratorTypeUsages[typeUsageFormData.respiratorType] = newTypeUsageErrors;
      }
    }

    // Errors will always have a respiratorTypeUsages key
    if (Object.keys(newErrors).length > 1
        || Object.keys(newErrors.respiratorTypeUsages).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as UpdateJobRoleRequestData;
  }

  async function submit() {
    setSubmitState('UNSUBMITTED');

    const accessToken = await getAccessToken();

    let requestData: UpdateJobRoleRequestData;

    try {
      requestData = validate();
      setValidationErrors({
        respiratorTypeUsages: {},
      });
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as UpdateJobRoleFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/job-roles/${jobRole.prn}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'PUT',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 200) {
        throw new Error();
      }

      const data = await result.json() as GetJobRoleData;

      setJobRole(data.jobRole);
      setSubmitState('SUCCESS');
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  const remainingRespiratorTypeOptions = RESPIRATOR_TYPE_OPTIONS.filter(
    (respiratorType) => {
      return !formData.respiratorTypeUsages.some(
        (typeUsage) => typeUsage.respiratorType === respiratorType.value,
      );
    },
  );

  return (
    <div className="flex flex-col gap-4">
      <Card title="Job Role Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.name} label="Job Role Name">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      name: evt.target.value,
                    });
                  }}
                  value={formData.name}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.description} label="Description">
              {() => (
                <TextareaInput
                  className="grow"
                  maxLength={512}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      description: evt.target.value,
                    });
                  }}
                  value={formData.description}
                />
              )}
            </FormGroup>
          </div>
        </div>
      </Card>
      {formData.respiratorTypeUsages.map((typeUsageData) => (
        <>
          <PageDivider />
          <Card
            actionItem={
              <Link
                className="hover:no-underline"
                onClick={() => {
                  const newRespiratorTypeUsages = formData.respiratorTypeUsages.filter(
                    (loopTypeUsageData) => loopTypeUsageData !== typeUsageData,
                  );
                  
                  setFormData({
                    ...formData,
                    respiratorTypeUsages: newRespiratorTypeUsages,
                  });
                }}>
                  <MaterialSymbol block icon="delete" />
              </Link>
            }
            key={typeUsageData.respiratorType}
            title={`Respirator Type: ${RESPIRATOR_TYPE_SHORT_LABELS[typeUsageData.respiratorType]}`}
          >
            <div className="max-w-2xl mx-auto">
              <div className="flex flex-col gap-y-10">
                <FormGroup
                  error={validationErrors.respiratorTypeUsages[typeUsageData.respiratorType]?.respiratoryHazards}
                  label="Respiratory Hazards"
                  sublabel="Select all respiratory hazards the employee will encounter while using this respirator type."
                >
                  {() => (
                    <SelectGroup<RespiratoryHazard>
                      className="flex gap-2 flex-wrap justify-start"
                      onChange={(respiratoryHazards) => {
                        const newRespiratorTypeUsages = formData.respiratorTypeUsages.map(
                          (loopTypeUsageData) => {
                            if (loopTypeUsageData === typeUsageData) {
                              return {
                                ...loopTypeUsageData,
                                respiratoryHazards,
                              };
                            } else {
                              return loopTypeUsageData;
                            }
                          }
                        )

                        setFormData({
                          ...formData,
                          respiratorTypeUsages: newRespiratorTypeUsages,
                        });
                      }}
                      options={[
                        {
                          disabled: ![
                            RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE,
                            RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_SCBA,
                          ].includes(typeUsageData.respiratorType),
                          key: RespiratoryHazard.CHEMICALS,
                          label: 'Chemicals',
                          value: RespiratoryHazard.CHEMICALS,
                        },
                        {
                          key: RespiratoryHazard.DUST,
                          label: 'Dust',
                          value: RespiratoryHazard.DUST,
                        },
                        {
                          disabled: ![
                            RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE,
                            RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_SCBA,
                          ].includes(typeUsageData.respiratorType),
                          key: RespiratoryHazard.FUMES,
                          label: 'Fumes',
                          value: RespiratoryHazard.FUMES,
                        },
                        {
                          key: RespiratoryHazard.INFECTIOUS_AGENTS,
                          label: 'Infectious Agents',
                          value: RespiratoryHazard.INFECTIOUS_AGENTS,
                        },
                        {
                          disabled: ![
                            RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_SCBA,
                          ].includes(typeUsageData.respiratorType),
                          key: RespiratoryHazard.OXYGEN_DEFICIENT,
                          label: 'Oxygen Deficient',
                          value: RespiratoryHazard.OXYGEN_DEFICIENT,
                        },
                        {
                          disabled: ![
                            RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_SCBA,
                          ].includes(typeUsageData.respiratorType),
                          key: RespiratoryHazard.OXYGEN_ENRICHED,
                          label: 'Oxygen Enriched',
                          value: RespiratoryHazard.OXYGEN_ENRICHED,
                        },
                        {
                          key: RespiratoryHazard.SENSITIZERS,
                          label: 'Sensitizers',
                          value: RespiratoryHazard.SENSITIZERS,
                        },
                        {
                          disabled: ![
                            RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE,
                            RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_SCBA,
                          ].includes(typeUsageData.respiratorType),
                          key: RespiratoryHazard.SMOKE,
                          label: 'Smoke',
                          value: RespiratoryHazard.SMOKE,
                        },
                        {
                          disabled: ![
                            RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE,
                            RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE,
                            RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
                            RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE,
                            RespiratorType.SUPPLIED_AIR_SCBA,
                          ].includes(typeUsageData.respiratorType),
                          key: RespiratoryHazard.VAPORS,
                          label: 'Vapors',
                          value: RespiratoryHazard.VAPORS,
                        },
                      ]}
                      value={typeUsageData.respiratoryHazards}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  error={validationErrors.respiratorTypeUsages[typeUsageData.respiratorType]?.usageScenarios}
                  label="Purpose of Respirator Use"
                  sublabel="Select all scenarios in which the employee will use this respirator type."
                >
                  {() => (
                    <SelectGroup<RespiratorUsageScenario>
                      className="flex gap-2 flex-wrap justify-start"
                      onChange={(usageScenarios) => {
                        const newRespiratorTypeUsages = formData.respiratorTypeUsages.map(
                          (loopTypeUsageData) => {
                            if (loopTypeUsageData === typeUsageData) {
                              return {
                                ...loopTypeUsageData,
                                usageScenarios,
                              };
                            } else {
                              return loopTypeUsageData;
                            }
                          }
                        )

                        setFormData({
                          ...formData,
                          respiratorTypeUsages: newRespiratorTypeUsages,
                        });
                      }}
                      options={[
                        {
                          key: RespiratorUsageScenario.ROUTINE,
                          label: 'Routine Use',
                          value: RespiratorUsageScenario.ROUTINE,
                        },
                        {
                          key: RespiratorUsageScenario.SITUATIONAL,
                          label: 'Situational Use',
                          value: RespiratorUsageScenario.SITUATIONAL,
                        },
                        {
                          key: RespiratorUsageScenario.FIREFIGHTING,
                          label: 'Structural Firefighting',
                          value: RespiratorUsageScenario.FIREFIGHTING,
                        },
                        {
                          key: RespiratorUsageScenario.ESCAPE,
                          label: 'Emergency Escape',
                          value: RespiratorUsageScenario.ESCAPE,
                        },
                        {
                          key: RespiratorUsageScenario.RESCUE,
                          label: 'Rescuing Others (Active Role)',
                          value: RespiratorUsageScenario.RESCUE,
                        },
                        {
                          key: RespiratorUsageScenario.RESCUE_OVERSIGHT,
                          label: 'Rescuing Others (Oversight Role)',
                          value: RespiratorUsageScenario.RESCUE_OVERSIGHT,
                        },
                      ]}
                      value={typeUsageData.usageScenarios}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  error={validationErrors.respiratorTypeUsages[typeUsageData.respiratorType]?.idlhEnvironment}
                  label="IDLH Environment"
                  sublabel="While using this respirator type, will the employee work in an IDLH (immediately dangerous to life or health) environment?"
                >
                  {() => (
                    <RadioGroup
                      className="grid grid-cols-2 sm:grid-cols-4 gap-2"
                      disabled={typeUsageData.respiratoryHazards.some(
                        (respiratoryHazard) => (
                          respiratoryHazard === RespiratoryHazard.OXYGEN_DEFICIENT
                          || respiratoryHazard === RespiratoryHazard.OXYGEN_ENRICHED
                        ),
                      )}
                      onChange={(idlhEnvironment) => {
                        const newRespiratorTypeUsages = formData.respiratorTypeUsages.map(
                          (loopTypeUsageData) => {
                            if (loopTypeUsageData === typeUsageData) {
                              return {
                                ...loopTypeUsageData,
                                idlhEnvironment,
                              };
                            } else {
                              return loopTypeUsageData;
                            }
                          }
                        )

                        setFormData({
                          ...formData,
                          respiratorTypeUsages: newRespiratorTypeUsages,
                        });
                      }}
                      value={typeUsageData.idlhEnvironment}
                    >
                      <RadioGroupOption value={YesNo.YES}>
                        Yes
                      </RadioGroupOption>
                      <RadioGroupOption value={YesNo.NO}>
                        No
                      </RadioGroupOption>
                    </RadioGroup>
                  )}
                </FormGroup>
                <FormGroup
                  error={validationErrors.respiratorTypeUsages[typeUsageData.respiratorType]?.averageUsageDuration}
                  label="Average Usage Duration"
                  sublabel="Select the length of time, on average, that the employee will wear this respirator type without a break."
                >
                  {() => (
                    <RadioGroup
                      className="grid grid-cols-2 sm:grid-cols-4 gap-2"
                      onChange={(averageUsageDuration) => {
                        const newRespiratorTypeUsages = formData.respiratorTypeUsages.map(
                          (loopTypeUsageData) => {
                            if (loopTypeUsageData === typeUsageData) {
                              return {
                                ...loopTypeUsageData,
                                averageUsageDuration,
                              };
                            } else {
                              return loopTypeUsageData;
                            }
                          }
                        )

                        setFormData({
                          ...formData,
                          respiratorTypeUsages: newRespiratorTypeUsages,
                        });
                      }}
                      value={typeUsageData.averageUsageDuration}
                    >
                      <RadioGroupOption value={RespiratorUsageDuration.ESCAPE_ONLY}>
                        Escape Only
                      </RadioGroupOption>
                      <RadioGroupOption value={RespiratorUsageDuration.LESS_THAN_TWO_HOURS}>
                        &lt; 2 Hours
                      </RadioGroupOption>
                      <RadioGroupOption value={RespiratorUsageDuration.TWO_TO_FOUR_HOURS}>
                        2-4 Hours
                      </RadioGroupOption>
                      <RadioGroupOption value={RespiratorUsageDuration.MORE_THAN_FOUR_HOURS}>
                        &gt; 4 Hours
                      </RadioGroupOption>
                    </RadioGroup>
                  )}
                </FormGroup>
                <FormGroup
                  error={validationErrors.respiratorTypeUsages[typeUsageData.respiratorType]?.physicalExertionLevel}
                  label="Physical Exertion"
                  sublabel="Select the average level of physical exertion expected of the employee while using this respirator type."
                >
                  {() => (
                    <RadioGroup
                      className="grid grid-cols-3 gap-2"
                      onChange={(physicalExertionLevel) => {
                        const newRespiratorTypeUsages = formData.respiratorTypeUsages.map(
                          (loopTypeUsageData) => {
                            if (loopTypeUsageData === typeUsageData) {
                              return {
                                ...loopTypeUsageData,
                                physicalExertionLevel,
                              };
                            } else {
                              return loopTypeUsageData;
                            }
                          }
                        )

                        setFormData({
                          ...formData,
                          respiratorTypeUsages: newRespiratorTypeUsages,
                        });
                      }}
                      value={typeUsageData.physicalExertionLevel}
                    >
                      <RadioGroupOption value={PhysicalExertionLevel.LIGHT}>
                        Light
                      </RadioGroupOption>
                      <RadioGroupOption value={PhysicalExertionLevel.MODERATE}>
                        Moderate
                      </RadioGroupOption>
                      <RadioGroupOption value={PhysicalExertionLevel.HEAVY}>
                        Heavy
                      </RadioGroupOption>
                    </RadioGroup>
                  )}
                </FormGroup>
                <FormGroup
                  error={validationErrors.respiratorTypeUsages[typeUsageData.respiratorType]?.additionalPpeTypes}
                  label="Additional PPE"
                  sublabel="Select all additional PPE (if any) that the employee will use together with this respirator type."
                >
                  {() => (
                    <SelectGroup<PpeType>
                      className="flex gap-2 flex-wrap justify-start"
                      onChange={(additionalPpeTypes) => {
                        const newRespiratorTypeUsages = formData.respiratorTypeUsages.map(
                          (loopTypeUsageData) => {
                            if (loopTypeUsageData === typeUsageData) {
                              return {
                                ...loopTypeUsageData,
                                additionalPpeTypes,
                              };
                            } else {
                              return loopTypeUsageData;
                            }
                          }
                        )

                        setFormData({
                          ...formData,
                          respiratorTypeUsages: newRespiratorTypeUsages,
                        });
                      }}
                      options={[
                        {
                          key: PpeType.EYE_PROTECTION,
                          label: 'Eye Protection',
                          value: PpeType.EYE_PROTECTION,
                        },
                        {
                          key: PpeType.FOOT_PROTECTION,
                          label: 'Foot Protection',
                          value: PpeType.FOOT_PROTECTION,
                        },
                        {
                          key: PpeType.FULL_BODY_COVERING,
                          label: 'Full Body Covering',
                          value: PpeType.FULL_BODY_COVERING,
                        },
                        {
                          key: PpeType.FULL_BODY_POSITIVE_PRESSURE_SUIT,
                          label: 'Full Body Positive Pressure Suit',
                          value: PpeType.FULL_BODY_POSITIVE_PRESSURE_SUIT,
                        },
                        {
                          key: PpeType.HAND_PROTECTION,
                          label: 'Hand Protection',
                          value: PpeType.HAND_PROTECTION,
                        },
                        {
                          key: PpeType.HEAD_PROTECTION,
                          label: 'Head Protection',
                          value: PpeType.HEAD_PROTECTION,
                        },
                        {
                          key: PpeType.HEARING_PROTECTION,
                          label: 'Hearing Protection',
                          value: PpeType.HEARING_PROTECTION,
                        },
                      ]}
                      value={typeUsageData.additionalPpeTypes}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  label="Environmental Hazards"
                  sublabel="Select all extreme environments (if any) the employee will encounter while using this respirator type."
                >
                  {() => (
                    <SelectGroup<WorkEnvironment>
                      className="flex gap-2 flex-wrap justify-start"
                      onChange={(workEnvironments) => {
                        const newRespiratorTypeUsages = formData.respiratorTypeUsages.map(
                          (loopTypeUsageData) => {
                            if (loopTypeUsageData === typeUsageData) {
                              return {
                                ...loopTypeUsageData,
                                workEnvironments,
                              };
                            } else {
                              return loopTypeUsageData;
                            }
                          }
                        )

                        setFormData({
                          ...formData,
                          respiratorTypeUsages: newRespiratorTypeUsages,
                        });
                      }}
                      options={[
                        {
                          key: WorkEnvironment.CONFINED_SPACE,
                          label: 'Confined Space',
                          value: WorkEnvironment.CONFINED_SPACE,
                        },
                        {
                          key: WorkEnvironment.EXTREME_COLD,
                          label: 'Extreme Cold',
                          value: WorkEnvironment.EXTREME_COLD,
                        },
                        {
                          key: WorkEnvironment.EXTREME_HEAT,
                          label: 'Extreme Heat',
                          value: WorkEnvironment.EXTREME_HEAT,
                        },
                        {
                          key: WorkEnvironment.EXTREME_HEIGHT,
                          label: 'Extreme Height',
                          value: WorkEnvironment.EXTREME_HEIGHT,
                        },
                        {
                          key: WorkEnvironment.EXTREME_HUMIDITY,
                          label: 'Extreme Humidity',
                          value: WorkEnvironment.EXTREME_HUMIDITY,
                        },
                      ]}
                      value={typeUsageData.workEnvironments}
                    />
                  )}
                </FormGroup>
              </div>
            </div>
          </Card>
        </>
      ))}
      {remainingRespiratorTypeOptions.length > 0 && (
        <>
          <PageDivider />
          <Card>
            <div className="max-w-2xl mx-auto">
              <div className="flex flex-col gap-y-3">
                {validationErrors.allRespiratorTypeUsages !== undefined && (
                  <FormError error={validationErrors.allRespiratorTypeUsages} />
                )}
                <FormLabel>Add a respirator type:</FormLabel>
                <div className="flex items-center gap-x-2">
                  <SelectInput<RespiratorType>
                    className="grow"
                    nullable
                    onChange={(_newRespiratorType) => {
                      setNewRespiratorType(_newRespiratorType);
                    }}
                    options={remainingRespiratorTypeOptions}
                    value={newRespiratorType}
                  />
                  <Button
                    disabled={newRespiratorType === null}
                    onClick={() => {
                      if (newRespiratorType !== null) {
                        setFormData({
                          ...formData,
                          respiratorTypeUsages: [
                            ...formData.respiratorTypeUsages,
                            {
                              additionalPpeTypes: [],
                              averageUsageDuration: null,
                              idlhEnvironment: null,
                              physicalExertionLevel: null,
                              respiratorType: newRespiratorType,
                              respiratoryHazards: [],
                              usageScenarios: [],
                              workEnvironments: [],
                            },
                          ],
                        });
                      }

                      setNewRespiratorType(null);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
      {submitState === 'SUCCESS' && (
        <SuccessBanner message="Changes to job role successfully saved." />
      )}
      {submitState === 'ERROR' && (
        <ErrorBanner message="Could not save job role. Please check your internet connection and try again." />
      )}
      <div className="flex justify-center">
        <Button
          disabled={submitState === 'SUBMITTING'}
          loading={submitState === 'SUBMITTING'}
          onClick={() => {
            void submit();
          }}
          size="lg"
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
