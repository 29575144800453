import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AccountType } from '@/features/accounts';
import { useApi } from '@/hooks/useApi';
import { AppInitializer, InitializerAccount } from '@/types/api';

import { AppContextEmployer, AppContextValue, appContext, authContext } from '../contexts';

import { MainLayout } from './MainLayout';

export function AppBootstrapper() {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      onRedirectCallback={(appState) => {
        if (appState?.returnTo !== undefined) {
          navigate(appState.returnTo);
        }
      }}
      useRefreshTokens
    >
      <AuthGuarantor />
    </Auth0Provider>
  );
}

function AuthGuarantor() {
  const {
    error,
    getAccessTokenSilently,
    isAuthenticated: auth0IsAuthenticated,
    isLoading: auth0IsLoading,
    loginWithRedirect: auth0LogIn,
    logout: auth0LogOut,
    user,
  } = useAuth0();

  const logIn = useCallback(() => {
    void auth0LogIn({
      appState: {
        returnTo: window.location.pathname,
      },
      authorizationParams: {
        audience: import.meta.env.VITE_API_BASE_URL,
      },
    });
  }, [auth0LogIn]);

  const getAccessTokenDangerously = useCallback(async () => {
    return await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_API_BASE_URL,
      },
    });
  }, [getAccessTokenSilently])

  const getAccessToken = useCallback(async () => {
    try {
      return await getAccessTokenDangerously();
    } catch (err) {
      logIn();
      return '';  // Make TypeScript happy; we're leaving the page anyway
    }
  }, [getAccessTokenDangerously, logIn]);

  const [authStatus, setAuthStatus] = useState<'AUTHENTICATED' | 'CHECKING'>('CHECKING');

  useEffect(() => {
    async function ensureAuthentication() {
      if (auth0IsLoading) {
        // Let Auth0 do its thing
        return;
      }

      if (!auth0IsAuthenticated) {
        logIn();
        return;
      }

      // The Auth0 lib reports isAuthenticated = true even when a valid refresh
      // token is not present (and therefore retrieving an access token will
      // fail). Test for that here.
      try {
        await getAccessTokenDangerously();
      } catch (err) {
        logIn();
        return;
      }

      setAuthStatus('AUTHENTICATED');
    }

    void ensureAuthentication();
  }, [auth0IsAuthenticated, auth0IsLoading, getAccessTokenDangerously, logIn]);

  if (authStatus === 'CHECKING') {
    return null;
  } else if (error !== undefined || user?.name === undefined) {
    // TODO | Handle error
    return null;
  }

  return (
    <authContext.Provider value={{
      getAccessToken,
      logOut() {
        void auth0LogOut({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
      user: {
        name: user.name,
      },
    }}>
      <InitializationGuarantor />
    </authContext.Provider>
  );
}

function InitializationGuarantor() {
  const navigate = useNavigate();
  const params = useParams();
  const [currentAccount, setCurrentAccount] = useState<InitializerAccount | undefined>(undefined);
  const [currentEmployer, setCurrentEmployer] = useState<AppContextEmployer | null>(null);

  const {
    data: appInitializer,
    error,
    isLoading,
  } = useApi<AppInitializer>({
    path: '/app-initializer',
    preInitialized: true,
  });

  useEffect(() => {
    if (appInitializer !== undefined) {
      if (appInitializer.managementAppInitializer.accounts.length === 1) {
        const onlyAccount = appInitializer.managementAppInitializer.accounts[0];
        
        setCurrentAccount(onlyAccount);

        if (onlyAccount.accountType === AccountType.EMPLOYER) {
          setCurrentEmployer(onlyAccount);
        }
      } else {
        navigate('/select-account');
      }
    }
  }, [appInitializer, navigate]);

  let appContextValue: AppContextValue;

  if (isLoading) {
    appContextValue = {
      isLoading: true,
    };
  } else if (error !== undefined) {
    appContextValue = {
      failed: true,
      isLoading: false,
    };
  } else {
    const accounts = appInitializer.managementAppInitializer.accounts;

    appContextValue = {
      accounts,
      currentAccount,
      currentEmployer,
      currentEmployerPrn: currentAccount?.accountType === 'EMPLOYER'
        ? currentAccount.prn
        : params.employerPrn ?? null,
      failed: false,
      isLoading: false,
      setCurrentAccount,
      setCurrentEmployer,
    };
  }

  return (
    <appContext.Provider value={appContextValue}>
      <MainLayout />
    </appContext.Provider>
  );
}
