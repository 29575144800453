import { ReactNode } from 'react';

export function TableHeader({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <thead>
      {children}
    </thead>
  );
}
