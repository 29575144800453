import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Card } from '@/components/Card';
import { FormGroup } from '@/components/FormGroup';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { PageDivider } from '@/components/PageDivider';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { EmployerRespiratorsList } from '@/features/respirator-fit-tests';
import { useApi } from '@/hooks/useApi';
import { useLocationState } from '@/hooks/useLocationState';
import { Employer, GetEmployerData } from '@/types/api';
import { accountHasProduct, ProductKey } from '@/utils/product';
import { CannabisJobTasksList } from '@/features/ground-cannabis-dust-medical-surveillance';

export function EmployerSettingsPage() {
  const { currentAccount, currentEmployer } = useAppContext();

  const { data } = useApi<GetEmployerData>({
    disable: currentEmployer === undefined || currentEmployer === null,
    path: `/employers/${currentEmployer?.prn}`,
  });

  const locationState = useLocationState<{ employer: Employer; }>();

  const [employer, setEmployer] = useState<Employer | null>(locationState?.employer ?? null);

  useEffect(() => {
    if (data !== undefined) {
      // Bust react-router's cached state and replace it with the fetched data
      setEmployer(data.employer);
      window.history.replaceState({}, '');
    }
  }, [data]);

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    if (employer === null) {
      return <PageBodySkeleton />;
    }

    return <EmployerSettingsPageContent employer={employer} setEmployer={setEmployer} />;
  } else {
    if (employer === null) {
      return (
        <PagePadder>
          <PageBodySkeleton />
        </PagePadder>
      );
    }

    return <EmployerSettingsPageWrapper employer={employer} setEmployer={setEmployer} />;
  }
}

function EmployerSettingsPageWrapper({
  employer,
  setEmployer,
}: {
  employer: Employer;
  setEmployer: Dispatch<SetStateAction<Employer | null>>;
}) {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Settings"
    >
      <EmployerSettingsPageContent employer={employer} setEmployer={setEmployer} />
    </Page>
  );
}

function EmployerSettingsPageContent({
  employer,
  // @ts-expect-error This is a placeholder
  // eslint-disable-next-line
  setEmployer,
}: {
  employer: Employer;
  setEmployer: Dispatch<SetStateAction<Employer | null>>;
}) {
  return (
    <div className="flex flex-col gap-4">
      <Card title="Employer Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup label="Employer Name">
              {() => (
                <div>{employer.preferredName}</div>
              )}
            </FormGroup>
          </div>
        </div>
      </Card>
      <PageDivider />
      <EmployerRespiratorsList />
      {accountHasProduct(employer, ProductKey.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE) && (
        <>
          <PageDivider />
          <CannabisJobTasksList />
        </>
      )}
    </div>
  );
}
