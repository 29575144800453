import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { AccountUser, QueryApiResponseData } from '@/types/api';

export function UsersListPage() {
  const { currentAccount, currentEmployer } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER
      && (currentEmployer ?? null) !== null) {
    return <UsersListPageContent />;
  } else {
    return <UsersListPageWrapper />;
  }
}

function UsersListPageWrapper() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Users"
    >
      <UsersListPageContent />
    </Page>
  );
}

// TODO | Remove this once there's a better way
const HIDDEN_USER_PRNS = [
  'prn:p:acu:98cc6ca9-7ac2-4998-a8f6-8912e50eeff0',
  'prn:p:acu:5de3b22a-9e1f-4b7b-aef1-6c2cfe5fbde3',
];

function UsersListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const useAccountPrn = currentEmployerPrn ?? currentAccount?.prn;

  const { data } = useApi<QueryApiResponseData<AccountUser>>({
    disable: useAccountPrn === undefined,
    path: `/account-users?accountPrn=${useAccountPrn}`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <Table
      actionItem={
        <Button
          onClick={() => {
            navigate(
              (currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                  && currentEmployerPrn !== null)
                ? `/employers/${currentEmployerPrn}/users/new`
                : '/users/new',
            );
          }}
          size="action-item"
        >
          <MaterialSymbol icon="add" />
          <span className="hidden sm:inline">New User</span>
        </Button>
      }
      title="Users"
    >
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell firstCol>Name</TableHeaderCell>
          <TableHeaderCell lastCol>Email</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {data.data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={2} firstCol lastCol>No Users Found</TableCell>
          </TableRow>
        ) : data.data.map((accountUser) => (
          !HIDDEN_USER_PRNS.includes(accountUser.prn) && (
            <TableRow key={accountUser.prn}>
              <TableCell firstCol header>{accountUser.firstName} {accountUser.lastName}</TableCell>
              <TableCell lastCol>{accountUser.emailAddress}</TableCell>
            </TableRow>
          )
        ))}
      </TableBody>
    </Table>
  );
}
