import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { Employer, QueryApiResponseData } from '@/types/api';

export function EmployersListPage() {
  const { currentAccount } = useAppContext();
  const navigate = useNavigate();

  const { data, failed, isLoading } = useApi<QueryApiResponseData<Employer>>({
    disable: currentAccount === undefined,
    path: `/employers?serviceProviderPrn=${currentAccount?.prn}`,
  });

  // TODO | If current account not service provider, 404

  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/'
        },
      ]}
      title="Employers"
    >
      {isLoading ? (
        <PageBodySkeleton />
      ) : failed ? (
        // TODO
        <div>ERROR: Could not load employers.</div>
      ) : (
        <Table
          actionItem={
            <Button
              onClick={() => {
                navigate('/employers/new');
              }}
              size="action-item"
            >
              <MaterialSymbol icon="add" />
              <span className="hidden sm:inline">New Employer</span>
            </Button>
          }
          title="Employers"
        >
          <TableBody>
            {data.data.map((employer) => (
              <TableRow
                className="cursor-pointer"
                key={employer.prn}
                onClick={() => {
                  navigate(`/employers/${employer.prn}/settings`, {
                    state: { employer },
                  });
                }}
              >
                <TableCell firstCol lastCol>
                  <Link state={{ employer }} to={`/employers/${employer.prn}/settings`}>
                    {employer.preferredName}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Page>
  );
}
