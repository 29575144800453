import { createBrowserRouter } from 'react-router-dom';

import { SelectAccountPage } from '@/features/accounts';
import { AppBootstrapper, IndexRedirector } from '@/features/app';
import { NewCannabisJobTaskPage } from './features/ground-cannabis-dust-medical-surveillance';
import { DashboardPage } from '@/features/dashboard';
import {
  EmployerBasePage,
  EmployerSettingsPage,
  EmployersListPage,
  NewEmployerPage,
} from '@/features/employers';
import { JobRoleDetailsPage, JobRolesListPage, NewJobRolePage } from '@/features/job-roles';
import { LocationDetailsPage, LocationsListPage, NewLocationPage } from '@/features/locations';
import { ProfilePage } from '@/features/profile';
import {
  NewQuestionnaireAccessCodePage,
  QuestionnaireAccessCodeDetailsPage,
  QuestionnaireAccessCodesListPage,
} from '@/features/questionnaire-access-codes';
import { ReportsPage } from '@/features/reporting';
import {
  NewEmployerRespiratorPage,
  NewRespiratorFitTestPage,
} from '@/features/respirator-fit-tests';
import { NewUserPage, UsersListPage } from '@/features/users';

export const router = createBrowserRouter([
  {
    element: <AppBootstrapper />,
    children: [
      {
        path: '/',
        children: [
          {
            index: true,
            element: <IndexRedirector />,
          },
          {
            path: 'dashboard',
            element: <DashboardPage />,
          },
          {
            path: 'employers',
            children: [
              {
                index: true,
                element: <EmployersListPage />,
              },
              {
                path: 'new',
                element: <NewEmployerPage />,
              },
              {
                path: ':employerPrn',
                element: <EmployerBasePage />,
                children: [
                  // {
                  //   path: 'employees',
                  //   element: <EmployeesListPage />,
                  // },
                  {
                    path: 'questionnaire-access-codes',
                    children: [
                      {
                        index: true,
                        element: <QuestionnaireAccessCodesListPage />,
                      },
                      {
                        path: 'new',
                        element: <NewQuestionnaireAccessCodePage />,
                      },
                      {
                        path: ':questionnaireAccessCodePrn',
                        element: <QuestionnaireAccessCodeDetailsPage />,
                      },
                    ],
                  },
                  {
                    path: 'job-roles',
                    children: [
                      {
                        index: true,
                        element: <JobRolesListPage />,
                      },
                      {
                        path: 'new',
                        element: <NewJobRolePage />,
                      },
                      {
                        path: ':jobRolePrn',
                        element: <JobRoleDetailsPage />
                      },
                    ],
                  },
                  {
                    path: 'locations',
                    children: [
                      {
                        index: true,
                        element: <LocationsListPage />,
                      },
                      {
                        path: 'new',
                        element: <NewLocationPage />,
                      },
                      {
                        path: ':locationPrn',
                        element: <LocationDetailsPage />,
                      },
                    ],
                  },
                  {
                    path: 'settings',
                    children: [
                      {
                        index: true,
                        element: <EmployerSettingsPage />,
                      },
                      {
                        path: 'cannabis-job-tasks',
                        children: [
                          {
                            path: 'new',
                            element: <NewCannabisJobTaskPage />,
                          },
                        ],
                      },
                      {
                        path: 'employer-respirators',
                        children: [
                          {
                            path: 'new',
                            element: <NewEmployerRespiratorPage />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'users',
                    children: [
                      {
                        index: true,
                        element: <UsersListPage />,
                      },
                      {
                        path: 'new',
                        element: <NewUserPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'job-roles',
            children: [
              {
                index: true,
                element: <JobRolesListPage />,
              },
              {
                path: 'new',
                element: <NewJobRolePage />,
              },
              {
                path: ':jobRolePrn',
                element: <JobRoleDetailsPage />,
              },
            ],
          },
          {
            path: 'locations',
            children: [
              {
                index: true,
                element: <LocationsListPage />,
              },
              {
                path: 'new',
                element: <NewLocationPage />,
              },
              {
                path: ':locationPrn',
                element: <LocationDetailsPage />,
              },
            ],
          },
          {
            path: 'profile',
            element: <ProfilePage />,
          },
          {
            path: 'questionnaire-access-codes',
            children: [
              {
                index: true,
                element: <QuestionnaireAccessCodesListPage />,
              },
              {
                path: 'new',
                element: <NewQuestionnaireAccessCodePage />,
              },
              {
                path: ':questionnaireAccessCodePrn',
                element: <QuestionnaireAccessCodeDetailsPage />,
              },
            ],
          },
          {
            path: 'reports',
            element: <ReportsPage />,
          },
          {
            path: 'respirator-fit-tests/new',
            element: <NewRespiratorFitTestPage />,
          },
          {
            path: 'select-account',
            element: <SelectAccountPage />,
          },
          // TODO | This will need to be adjusted once SPs have a settings page
          {
            path: 'settings',
            children: [
              {
                index: true,
                element: <EmployerSettingsPage />,
              },
              {
                path: 'cannabis-job-tasks',
                children: [
                  {
                    path: 'new',
                    element: <NewCannabisJobTaskPage />,
                  },
                ],
              },
              {
                path: 'employer-respirators',
                children: [
                  {
                    path: 'new',
                    element: <NewEmployerRespiratorPage />,
                  },
                ],
              },
            ]
          },
          {
            path: 'users',
            children: [
              {
                index: true,
                element: <UsersListPage />,
              },
              {
                path: 'new',
                element: <NewUserPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
