import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { JobRole, QueryApiResponseData } from '@/types/api';

export function JobRolesListPage() {
  const { currentAccount } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return <JobRolesListPageContent />;
  } else {
    return <JobRolesListPageWrapper />;
  }
}

function JobRolesListPageWrapper() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Job Roles"
    >
      <JobRolesListPageContent />
    </Page>
  );
}

function JobRolesListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const { data } = useApi<QueryApiResponseData<JobRole>>({
    disable: currentEmployerPrn === undefined || currentEmployerPrn === null,
    path: `/job-roles?employerPrn=${currentEmployerPrn}&sortBy=name`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <Table
      actionItem={
        <Button
          onClick={() => {
            navigate(
              currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                ? `/employers/${currentEmployerPrn}/job-roles/new`
                : '/job-roles/new',
            );
          }}
          size="action-item"
        >
          <MaterialSymbol icon="add" />
          <span className="hidden sm:inline">New Job Role</span>
        </Button>
      }
      title="Job Roles"
    >
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell firstCol>Name</TableHeaderCell>
          <TableHeaderCell lastCol>Description</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {data.data.map((jobRole) => (
          <TableRow
            className="cursor-pointer"
            key={jobRole.prn}
            onClick={() => {
              navigate(
                currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                  ? `/employers/${currentEmployerPrn}/job-roles/${jobRole.prn}`
                  : `/job-roles/${jobRole.prn}`,
                {
                  state: { jobRole },
                },
              );
            }}
          >
            <TableCell firstCol header>
              <Link
                state={{ jobRole }}
                to={
                  currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                    ? `/employers/${currentEmployerPrn}/job-roles/${jobRole.prn}`
                    : `/job-roles/${jobRole.prn}`
                }
              >
                {jobRole.name}
              </Link>
            </TableCell>
            <TableCell lastCol>{jobRole.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
