import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { TextInput } from '@/components/TextInput';
import { AccountType } from '@/features/accounts';
import { useAppContext, useAuthContext } from '@/features/app';
import { CreateCannabisJobTaskRequestData } from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { ValidationError } from '@/utils/validation';

import { CreateCannabisJobTaskFormData, CreateCannabisJobTaskFormErrors } from '../types';

export function NewCannabisJobTaskPage() {
  const { currentAccount } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return (
      <div className="flex flex-col gap-4">
        <div className="font-display text-lg pl-4 sm:pl-6 md:pl-0">New Cannabis Job Task</div>
        <NewCannabisJobTaskPageContent />
      </div>
    );
  } else {
    return <NewCannabisJobTaskPageWrapper />;
  }
}

export function NewCannabisJobTaskPageWrapper() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Settings',
          path: '/settings'
        },
      ]}
      title="New Cannabis Job Task"
    >
      <NewCannabisJobTaskPageContent />
    </Page>
  );
}

export function NewCannabisJobTaskPageContent() {
  const [formData, setFormData] = useState<CreateCannabisJobTaskFormData>({
    name: '',
  });

  const [validationErrors, setValidationErrors] = useState<CreateCannabisJobTaskFormErrors>({});

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const { currentAccount, currentEmployerPrn } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const navigate = useNavigate();

  function validate(): CreateCannabisJobTaskRequestData {
    if (currentEmployerPrn === undefined || currentEmployerPrn === null) {
      throw new Error();
    }

    const requestData: Partial<CreateCannabisJobTaskRequestData> = {
      employer: {
        prn: currentEmployerPrn,
      },
    };

    const newErrors: CreateCannabisJobTaskFormErrors = {};

    if (formData.name.trim().length > 0) {
      requestData.name = formData.name;
    } else {
      newErrors.name = 'This field is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as CreateCannabisJobTaskRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: CreateCannabisJobTaskRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as CreateCannabisJobTaskFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/cannabis-job-tasks`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 201) {
        throw new Error();
      }

      setSubmitState('SUCCESS');

      navigate(
        currentAccount?.accountType === AccountType.SERVICE_PROVIDER
          ? `/employers/${currentEmployerPrn}/settings`
          : '/settings',
      );
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Card title="Cannabis Job Task Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.name} label="Task Name">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      name: evt.target.value,
                    });
                  }}
                  value={formData.name}
                />
              )}
            </FormGroup>
          </div>
        </div>
      </Card>
      <div className="flex flex-col gap-y-4">
        {submitState === 'ERROR' && (
          <ErrorBanner message="Could not create cannabis job task. Please check your internet connection and try again." />
        )}
        <div className="flex justify-center">
          <Button
            disabled={submitState === 'SUBMITTING'}
            loading={submitState === 'SUBMITTING'}
            onClick={() => {
              void submit();
            }}
            size="lg"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
