import { useNavigate } from 'react-router-dom';

import { Link } from '@/components/Link';
import { Page } from '@/components/Page';
import { useAppContext } from '@/features/app';
import { Card } from '@/components/Card';

import { AccountType } from '../enums';

export function SelectAccountPage() {
  const { accounts, currentAccount, setCurrentAccount, setCurrentEmployer } = useAppContext();
  const navigate = useNavigate();

  return accounts === undefined ? null : (
    <Page title={currentAccount === undefined ? 'Select Account' : 'Switch Account'}>
      <Card>
        <div className="flex flex-col gap-y-4">
          {[...accounts].sort(
            (a, b) => a.preferredName.toLowerCase().localeCompare(b.preferredName.toLowerCase()),
          ).map((account) => (
            <Link
              onClick={() => {
                setCurrentAccount(account);

                if (account.accountType === AccountType.EMPLOYER) {
                  setCurrentEmployer(account);
                } else {
                  setCurrentEmployer(null);
                }

                navigate('/dashboard');
              }}
              key={account.prn}
            >
              {account.preferredName}
            </Link>
          ))}
        </div>
      </Card>
    </Page>
  );
}
