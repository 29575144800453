import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { RESPIRATOR_TYPE_SHORT_LABELS } from '@/features/respiratory-protection/utils';
import { useApi } from '@/hooks/useApi';
import { EmployerRespirator, QueryApiResponseData } from '@/types/api';

export function EmployerRespiratorsList() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const { data } = useApi<QueryApiResponseData<EmployerRespirator>>({
    path: `/employer-respirators?employerPrn=${currentEmployerPrn}&sortBy=model`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <Table
      actionItem={
        <Button
          onClick={() => {
            navigate(
              currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                ? `/employers/${currentEmployerPrn}/settings/employer-respirators/new`
                : '/settings/employer-respirators/new',
            );
          }}
          size="action-item"
        >
          <MaterialSymbol block icon="add" />
          <span className="hidden sm:inline">New Respirator Model</span>
        </Button>
      }
      title="Respirator Models"
    >
      {data.data.length > 0 && (
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell firstCol>Model</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell lastCol>Passing FF</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
      )}
      <TableBody>
        {data.data.length === 0 ? (
          <TableRow className="italic">
            <TableCell colSpan={3} firstCol lastCol>No respirators have been set up. Click the + button above to add a respirator.</TableCell>
          </TableRow>
        ) : data.data.map((employerRespirator) => (
          <TableRow key={employerRespirator.prn}>
            <TableCell firstCol header>{employerRespirator.make} {employerRespirator.model}</TableCell>
            <TableCell>{RESPIRATOR_TYPE_SHORT_LABELS[employerRespirator.respiratorType]}</TableCell>
            <TableCell lastCol>{employerRespirator.passingQuantitativeFitFactor}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
