import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from '@/components/Card';
import { Button } from '@/components/Button';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { SelectInput } from '@/components/SelectInput';
import { TextInput } from '@/components/TextInput';
import { AccountType } from '@/features/accounts';
import { useAppContext, useAuthContext } from '@/features/app';
import { RESPIRATOR_TYPE_OPTIONS, RespiratorType } from '@/features/respiratory-protection';
import { CreateEmployerRespiratorRequestData } from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { ValidationError } from '@/utils/validation';

import { CreateEmployerRespiratorFormData, CreateEmployerRespiratorFormErrors } from '../types';

export function NewEmployerRespiratorPage() {
  const { currentAccount } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return (
      <div className="flex flex-col gap-4">
        <div className="font-display text-lg pl-4 sm:pl-6 md:pl-0">New Respirator Model</div>
        <NewEmployerRespiratorPageContent />
      </div>
    );
  } else {
    return <NewEmployerRespiratorPageWrapper />;
  }
}


function NewEmployerRespiratorPageWrapper() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Settings',
          path: '/settings'
        },
      ]}
      title="New Respirator Model"
    >
      <NewEmployerRespiratorPageContent />
    </Page>
  );
}

function NewEmployerRespiratorPageContent() {
  const [formData, setFormData] = useState<CreateEmployerRespiratorFormData>({
    make: '',
    model: '',
    passingQuantitativeFitFactor: '',
    respiratorType: null,
  });

  const [validationErrors, setValidationErrors] = useState<CreateEmployerRespiratorFormErrors>({});

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const { currentAccount, currentEmployerPrn } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (formData.respiratorType !== null) {
      let passingQuantitativeFitFactor = '100';

      if ([
        RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE,
        RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE,
        RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
        RespiratorType.SUPPLIED_AIR_SCBA,
      ].includes(formData.respiratorType)) {
        passingQuantitativeFitFactor = '500';
      }

      setFormData((formData) => ({
        ...formData,
        passingQuantitativeFitFactor,
      }));
    }
  }, [formData.respiratorType]);

  function validate(): CreateEmployerRespiratorRequestData {
    if (currentEmployerPrn === undefined || currentEmployerPrn === null) {
      throw new Error();
    }

    const requestData: Partial<CreateEmployerRespiratorRequestData> = {
      employer: {
        prn: currentEmployerPrn,
      },
    };

    const newErrors: CreateEmployerRespiratorFormErrors = {};

    if (formData.respiratorType !== null) {
      requestData.respiratorType = formData.respiratorType;
    } else {
      newErrors.respiratorType = 'This field is required.';
    }

    if (formData.make.trim().length > 0) {
      requestData.make = formData.make;
    } else {
      newErrors.make = 'This field is required.';
    }

    if (formData.model.trim().length > 0) {
      requestData.model = formData.model;
    } else {
      newErrors.model = 'This field is required.';
    }

    if (formData.passingQuantitativeFitFactor.trim().length > 0) {
      if (/^[1-9][0-9]*$/.test(formData.passingQuantitativeFitFactor)) {
        const numericFitFactor = parseInt(formData.passingQuantitativeFitFactor);

        if (numericFitFactor > 0 && numericFitFactor <= 1000000) {
          requestData.passingQuantitativeFitFactor = numericFitFactor;
        } else {
          newErrors.passingQuantitativeFitFactor = 'Fit factor must be between 1 and 1000000.';
        }
      } else {
        newErrors.passingQuantitativeFitFactor = 'This field is invalid.';
      }
    } else {
      newErrors.passingQuantitativeFitFactor = 'This field is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as CreateEmployerRespiratorRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: CreateEmployerRespiratorRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as CreateEmployerRespiratorFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/employer-respirators`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 201) {
        throw new Error();
      }

      setSubmitState('SUCCESS');

      navigate(
        currentAccount?.accountType === AccountType.SERVICE_PROVIDER
          ? `/employers/${currentEmployerPrn}/settings`
          : '/settings',
      );
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Card title="Respirator Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.respiratorType} label="Respirator Type">
              {({ id }) => (
                <SelectInput<RespiratorType>
                  id={id}
                  onChange={(respiratorType) => {
                    setFormData({
                      ...formData,
                      respiratorType,
                    });
                  }}
                  options={RESPIRATOR_TYPE_OPTIONS.map((option) => ({
                    key: option.value,
                    label: option.label,
                    value: option.value,
                  }))}
                  value={formData.respiratorType}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.make} label="Manufacturer">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      make: evt.target.value,
                    });
                  }}
                  value={formData.make}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.model} label="Model">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      model: evt.target.value,
                    });
                  }}
                  value={formData.model}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.passingQuantitativeFitFactor} label="Passing Fit Factor">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={7}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      passingQuantitativeFitFactor: evt.target.value,
                    });
                  }}
                  value={formData.passingQuantitativeFitFactor}
                />
              )}
            </FormGroup>
          </div>
        </div>
      </Card>
      <div className="flex flex-col gap-y-4">
        {submitState === 'ERROR' && (
          <ErrorBanner message="Could not create respirator. Please check your internet connection and try again." />
        )}
        <div className="flex justify-center">
          <Button
            disabled={submitState === 'SUBMITTING'}
            loading={submitState === 'SUBMITTING'}
            onClick={() => {
              void submit();
            }}
            size="lg"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
