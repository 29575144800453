import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page, PagePadder } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { Location, QueryApiResponseData } from '@/types/api';

export function LocationsListPage() {
  const { currentAccount } = useAppContext();

  if (currentAccount === undefined) {
    return (
      <PagePadder>
        <PageBodySkeleton />
      </PagePadder>
    );
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return <LocationsListPageContent />;
  } else {
    return <LocationsListPageWrapper />;
  }
}

function LocationsListPageWrapper() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Locations"
    >
      <LocationsListPageContent />
    </Page>
  );
}

function LocationsListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const { data } = useApi<QueryApiResponseData<Location>>({
    disable: currentEmployerPrn === undefined || currentEmployerPrn === null,
    path: `/locations?employerPrn=${currentEmployerPrn}&sortBy=name`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <Table
      actionItem={
        <Button
          onClick={() => {
            navigate(
              currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                ? `/employers/${currentEmployerPrn}/locations/new`
                : '/locations/new',
            );
          }}
          size="action-item"
        >
          <MaterialSymbol icon="add" />
          <span className="hidden sm:inline">New Location</span>
        </Button>
      }
      title="Locations"
    >
      <TableBody>
        {data.data.map((location) => (
          <TableRow
            className="cursor-pointer"
            key={location.prn}
            onClick={() => {
              navigate(
                currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                  ? `/employers/${currentEmployerPrn}/locations/${location.prn}`
                  : `/locations/${location.prn}`,
                {
                  state: { location },
                },
              );
            }}
          >
            <TableCell firstCol header lastCol>
              <Link
                state={{ location }}
                to={
                  currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                    ? `/employers/${currentEmployerPrn}/locations/${location.prn}`
                    : `/locations/${location.prn}`
                }
              >
                {location.name}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
