export enum ProductKey {
  GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE = 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE',
  RESPIRATOR_MEDICAL_EVALUATION = 'RESPIRATOR_MEDICAL_EVALUATION',
}

type HasProducts = {
  products: {
    key: ProductKey;
    name: string;
  }[];
};

export function accountHasProduct(account: HasProducts, productKey: ProductKey): boolean {
  return account.products.some((product) => product.key === productKey);
}
