export function PageDivider() {
  return (
    <div className="flex justify-center gap-x-3">
      <div className="bg-purple-400/25 w-1.5 h-1.5"></div>
      <div className="bg-purple-400/50 w-1.5 h-1.5"></div>
      <div className="bg-purple-400/75 w-1.5 h-1.5"></div>
      <div className="bg-purple-400/50 w-1.5 h-1.5"></div>
      <div className="bg-purple-400/25 w-1.5 h-1.5"></div>
    </div>
  );
}
